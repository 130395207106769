import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AgGridModule} from 'ag-grid-angular';
import {ConfirmDialogModule} from '../../projects/list-playlist/src/app/confirm-dialog/confirm-dialog.module';

import {AppComponent} from './Components/app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {MainComponent} from './Components/main/main.component';
import {LoginComponent} from './Components/login/login.component';
import {PlaylistListComponent} from './Components/playlist-list/playlist-list.component';
import {AngularMaterialModule} from './shared/angular-material.module';
import {StorageUsageComponent} from './Components/storage-usage/storage-usage.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormatBytesPipe} from './Pipes/format-bytes.pipe';
import {TimeFormatPipe} from './Pipes/time-format.pipe';
import {ThemeThumbnailComponent} from './Components/theme-thumbnail/theme-thumbnail.component';
import {provideHttpClient} from '@angular/common/http';
import {TicksTimeFormatPipe} from './Pipes/ticks-time-format.pipe';
import {CookieService} from 'ngx-cookie-service';
import {ServiceWorkerModule} from '@angular/service-worker';
import {NgOptimizedImage} from "@angular/common";
import {AuthModule, provideAuth0} from "@auth0/auth0-angular";


@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    PlaylistListComponent,
    StorageUsageComponent,
    FormatBytesPipe,
    TimeFormatPipe,
    ThemeThumbnailComponent,
    TicksTimeFormatPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    AgGridModule,
    AuthModule.forRoot(),
    // ServiceWorkerModule.register('assets/worker.js', {
    //   enabled: true,
    //   scope: 'app',
    //   registrationStrategy: 'registerWhenStable:3000'
    // }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      scope: 'root',
      registrationStrategy: 'registerWhenStable:30000'
    }),
    ConfirmDialogModule,
    NgOptimizedImage,
  ],
  providers: [CookieService,
    provideHttpClient(),
    provideAuth0({
      domain: 'dev-rporsxw4.us.auth0.com',
      clientId: 'itstLSm8kkNwRCEGbq1UDkTdetmoken7',
      authorizationParams: {
        redirect_uri: window.location.origin
      }
    }),
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
