import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {HeaderService} from "../Services/header.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('empty') empty: TemplateRef<any>;

  constructor(private headerService: HeaderService, private router: Router) {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('music-worker.js').then((registration) => {
        registration.addEventListener('updatefound', () => {
          const newWorker = registration.installing;
          newWorker?.addEventListener('statechange', () => {
            if (newWorker.state === 'activated') {
              newWorker.postMessage({action: 'ping'});
            }
          });
        });

        if (registration.active) {  // If the service worker is already active
          registration.active.postMessage({action: 'ping'});
        }

        navigator.serviceWorker.addEventListener('message', (event) => {
          if (event.data.action === 'pong') {
            console.log('Service Worker Ping Pong Success');
          }
        });
      }).catch((error) => {
        console.error('Service Worker Registration Failed', error);
      });
    }

  }

  ngOnInit() {
    this.headerService.titleTemplate = this.empty;
    this.router.events.subscribe(() => {
      this.headerService.titleTemplate = this.empty;
    });
  }
}
