<h1 mat-dialog-title>
  Edit Playlist
</h1>
<div mat-dialog-content style="height: calc(100vh - 11rem);max-height: none;overflow: hidden;">
  <a (click)="expanded = !expanded" style="display: flex; align-items: center;">More Properties
    <mat-icon [class.expanded]="expanded">expand_more</mat-icon>
  </a>
  <div [class.expanded]="expanded" class="more">
    <mat-form-field style="width: 100%;">
      <mat-label>Parent</mat-label>
      <mat-select [(ngModel)]="parentId">
        @for (option of flatPlaylist; track option) {
          <mat-option [value]="option.id">{{ option.title }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field style="width: 100%;">
      <mat-label>Title</mat-label>
      <input [(ngModel)]="title" matInput>
    </mat-form-field>
  </div>
  <mat-tab-group [class.expanded]="expanded">
    <mat-tab label="Songs">
      <ng-container>
        <div (fileDropped)="dropFile($event)" appDnd>
          <mat-form-field style="width: 100%;">
            <mat-label>Youtube Url</mat-label>
            <input [(ngModel)]="youtubeUrl" matInput>
            <button (click)="youtubeUpload()" mat-button matSuffix>
              <mat-icon>upload</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field style="width: 100%;">
            <mat-label>Song Search</mat-label>
            <input [(ngModel)]="songSearch" matInput>
          </mat-form-field>
          <div (cdkDropListDropped)="drop($event)" [class.expanded]="!expanded" cdkDropList class="songContainer">
            @for (song of songs | songSearch : songSearch; track song) {
              <app-song-card [song]="song"
                             [showEdit]="true"
                             cdkDrag
              >
                <div style="flex: 1 1 auto"></div>
                <div>
                  <div>
                    <button mat-icon-button cdkDragHandle>
                      <mat-icon>drag_handle</mat-icon>
                    </button>
                  </div>
                  <div>
                    <button mat-icon-button
                            (click)="remove(song)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </div>
              </app-song-card>
            }
          </div>
        </div>
      </ng-container>
    </mat-tab>
    <mat-tab label="Unassigned Songs">
      <ng-container>
        <mat-form-field style="width: 100%;">
          <mat-label>Song Search</mat-label>
          <input [(ngModel)]="songSearch" matInput>
        </mat-form-field>
        <div class="songContainer">
          @for (song of unassignedSongs | songSearch : songSearch; track song) {
            <app-song-card [song]="song"
            >
              <button mat-icon-button
                      (click)="add(song)">
                <mat-icon>add</mat-icon>
              </button>
            </app-song-card>
          }
        </div>
      </ng-container>
    </mat-tab>
  </mat-tab-group>
</div>
<div mat-dialog-actions>
  <div class="spacer"></div>
  <button mat-button mat-dialog-close>Cancel</button>
  <button (click)="save()" cdkFocusInitial mat-button>Save</button>
</div>
