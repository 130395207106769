<mat-nav-list>
  @for (playlist of list; track playlist) {
    <div class="menu-item-container" [class.isActive]="playlist.isActive">
      <div style="display: flex">
        @if (!playlist.children?.length) {
          <a (click)="drawer.close()"
            [routerLinkActive]="'active'"
            [routerLink]="['/main', 'playlist', playlist.id]"
            mat-list-item>
            <span [style.padding-left.rem]="indent * .5">{{playlist.title}}</span>
          </a>
        }
        @if (playlist.children?.length) {
          <a
            (click)="toggleOpen(playlist, true); drawer.close()"
            [routerLinkActive]="'active'"
            [routerLink]="['/main', 'playlist', playlist.id]"
            mat-list-item>
            <span [style.padding-left.rem]="indent * .5">{{playlist.title}}</span>
          </a>
        }
        @if (playlist.children?.length) {
          <button mat-icon-button
            (click)="toggleOpen(playlist)">
            @if (playlist.isActive) {
              <mat-icon>expand_less</mat-icon>
            }
            @if (!playlist.isActive) {
              <mat-icon>expand_more</mat-icon>
            }
          </button>
        }
        <!--      <button mat-icon-button [mat-menu-trigger-for]="menu">-->
        <!--        <mat-icon>more_vert</mat-icon>-->
      <!--      </button>-->
      <!--      <mat-menu #menu>-->
      <!--        <a mat-menu-item-->
      <!--           [appConfirm]="delete.bind(this, playlist)"-->
    <!--           confirmMessage="Are you sure you want to delete {{playlist.title}}?">Delete</a>-->
  <!--      </mat-menu>-->
</div>
@if (playlist.isActive) {
  <app-playlist-list [drawer]="drawer"
    [indent]="indent + 1"
    [list]="playlist.children"
  [parent]="playlist.title + '>'"></app-playlist-list>
}
</div>
}
</mat-nav-list>
