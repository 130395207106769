import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as mm from 'music-metadata-browser';
import { IPicture } from 'music-metadata-browser';
import { tap } from 'rxjs/operators';
import _ from 'underscore';
import { ISong } from '../../../../../src/app/Models/ISong';
import { Playlist } from '../../../../../src/app/Models/playlist';
import { Song } from '../../../../../src/app/Models/song';
import { ThumbnailSet } from '../../../../../src/app/Models/thumbnailSet';
import { DataStorageService } from '../../../../../src/app/Services/data-storage.service';
import { PlayerService } from '../../../../../src/app/Services/player.service';
import { SongsService } from '../../../../../src/app/Services/songs.service';

@Component({
  selector: 'app-edit-playlist',
  templateUrl: './edit-playlist.component.html',
  styleUrls: ['./edit-playlist.component.scss'],
})
export class EditPlaylistComponent implements OnInit {
  public playlist: Playlist[];
  public flatPlaylist: any[];
  public title: any;
  public parentId: any;
  public songs: Song[];
  public unassignedSongs: Song[];
  public songSearch: string;
  public youtubeUrl: string;
  public expanded = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: Playlist,
              private matDialogRef: MatDialogRef<EditPlaylistComponent>,
              private matSnackBar: MatSnackBar,
              private songService: SongsService,
              private dataService: DataStorageService,
              private playerService: PlayerService) {
    this.parentId = this.data?.parentId;
    this.title = this.data?.title;
    this.songs = _.sortBy(this.data.songs, (s: Song) => s.ordering);
  }

  ngOnInit(): void {
    this.songService.getSongs()
      .pipe(tap(songs => {
        this.unassignedSongs = songs.filter(s => this.songs.every(s1 => s1.id !== s.id));
      }))
      .subscribe();
    this.songService.getPlaylists()
      .pipe(tap(playlist => {
        this.playlist = playlist;
        this.flatPlaylist = [{id: null, title: ''}, ...this.flattenList(playlist, '')];
      }))
      .subscribe();
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.songs, event.previousIndex, event.currentIndex);
    let index = 1;
    this.songs.forEach(s => s.ordering = index++);
  }

  private flattenList(playlist: Playlist[], parentPath: string): any[] {
    let results = [];
    playlist.forEach(p => {
      let path = parentPath;
      if (path !== '') {
        path = path + ' > ';
      }
      results.push({
        title: path + p.title,
        id: p.id
      });
      if (p.children) {
        results = [...results, ...this.flattenList(p.children, path + p.title)];
      }
    });
    return results;
  }

  public add(song: Song): void {
    this.songs.push(song);
    this.unassignedSongs = _.without(this.unassignedSongs, song);
  }

  public remove = (song: Song): void => {
    this.unassignedSongs.push(song);
    this.songs = _.without(this.songs, song);
  }

  public youtubeUpload(): void {
    this.playerService.youtubeSong(this.youtubeUrl)
      .pipe(tap(song => {
        if (!song) {
          return;
        }
        this.youtubeUrl = '';
        this.songs.push(song);
      }))
      .subscribe();
  }

  public save(): void {
    this.data.parentId = this.parentId;
    this.data.title = this.title;
    this.data.songs = this.songs;
    this.playerService.savePlaylist(this.data)
      .pipe(tap((playlist) => {
        this.dataService.getPlaylist().subscribe();
        this.matSnackBar.open('Saved Playlist', null, {duration: 1500});
        this.matDialogRef.close(true);
      }))
      .subscribe();
  }

  public async dropFile(files: FileList): Promise<void> {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const iFileAnalysis = await this.parseFile(file);
      const song = {
        title: iFileAnalysis.metadata.common.title,
        album: iFileAnalysis.metadata.common.album,
        artists: iFileAnalysis.metadata.common.artists,
        images: (await this.getThumbnails(iFileAnalysis.metadata.common.picture)) ?? [],
        duration: iFileAnalysis.metadata.format.duration,
        ordering: iFileAnalysis.metadata.common.track.no
      } as ISong;
      console.log(iFileAnalysis, song);
      this.songService.uploadSong(song, file)
        .pipe(tap(newSong => {
          this.add(newSong);
        }))
        .subscribe();
    }

  }

  // private prepareNativeTags(tags): { type: string, tags: { id: string, value: string }[] }[] {
  //   return Object.keys(tags).map(type => {
  //     return {
  //       type,
  //       tags: tags[type]
  //     };
  //   });
  // }
  //
  // private prepareTags(labels: TagLabel[], tags): ITagText[] {
  //   return labels.filter(label => tags.hasOwnProperty(label.key)).map(label => {
  //       const av = Array.isArray(tags[label.key]) ? tags[label.key] : [tags[label.key]];
  //       return {
  //         key: label.key,
  //         label: {text: label.label, ref: label.keyRef},
  //         value: av.map(v => {
  //           return {
  //             text: label.toText ? label.toText(v) : v,
  //             ref: label.valueRef ? label.valueRef(v) : undefined
  //           };
  //         })
  //       };
  //     }
  //   );
  // }
  private async parseFile(file: File): Promise<IFileAnalysis> {
    const result: IFileAnalysis = {
      file
    };
    // this.results.push(result);
    try {
      result.metadata = await mm.parseBlob(file);
      console.log(result);
      return result;
    } catch (err) {
      result.parseError = err.message;
      return null;
    }
  }

  private async getThumbnail(picture: IPicture): Promise<ThumbnailSet> {
    return await new Promise(async (resolve) => {
      const base64 = await this.getBase64(picture);
      if (base64 == null){
        resolve(null);
      }
      const image = new Image();
      image.src = base64;
      image.onload = () => {
        resolve({
          url: base64,
          resolution: {
            height: image.height,
            width: image.width
          }
        } as ThumbnailSet);
      };
    });
  }

  private getBase64(picture: IPicture): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = () => resolve(null);
      reader.readAsDataURL(new Blob( [picture.data], {type: picture.format}));
    });
  }

  private async getThumbnails(pictures: IPicture[]): Promise<ThumbnailSet[]> {
    if (!pictures?.length) {
      return new Promise(resolve => resolve(null));
    }
    const thumbnails = await Promise.all(pictures?.map(async p => await this.getThumbnail(p)));
    return thumbnails ?? [];
  }
}

interface IFileAnalysis {
  file: File | IUrlAsFile;
  metadata?: mm.IAudioMetadata;
  parseError?: Error;
}
export interface TagLabel {
  /**
   * API tag property name
   */
  key: string;
  /**
   * Human readable label describing key
   */
  label: string;
  /**
   * Convert tag label to human-readable string
   */
  toText?: (value: any) => string;
  keyRef?: string;
  valueRef?: (value: string) => string;
}

interface IUrlAsFile {
  name: string;
  type: string;
}
interface IValue {
  text: string;
  ref?: string;
}

interface ITagText {
  key: string;
  label: IValue;
  value: IValue[];
}
export const formatLabels: TagLabel[] = [
  {
    key: 'container',
    label: 'Audio container'
  },
  {
    key: 'codec',
    label: 'Audio codec(s)'
  },
  {
    key: 'tool',
    label: 'Tool (encoder)'
  },
  {
    key: 'codecProfile',
    label: 'Codec profile'
  }, {
    key: 'tagTypes',
    label: 'Tag header type(s)'
  }, {
    key: 'duration',
    label: 'Duration',
    toText: v => Math.round(v * 100) / 100 + ' seconds'
  }, {
    key: 'bitrate',
    label: 'Bit-rate',
    toText: v => Math.round(v / 1000) + ' kbps'
  }, {
    key: 'sampleRate',
    label: 'Sample-rate',
    toText: v => Math.round(v / 100) / 10 + ' hz'
  }, {
    key: 'bitsPerSample',
    label: 'Audio bit depth'
  }, {
    key: 'lossless',
    label: 'Lossless?'
  }, {
    key: 'numberOfChannels',
    label: 'Number of channels'
  }, {
    key: 'audioMD5',
    label: 'Audio MD5 hash'
  }
];

const mbBaseUrl = 'https://musicbrainz.org';

export const commonLabels: TagLabel[] = [
  {
    key: 'title',
    label: 'Track title'
  }, {
    key: 'titlesort',
    label: 'Track title, formatted for alphabetic ordering'
  }, {
    key: 'subtitle',
    label: 'Contains the subtitle of the content'
  }, {
    key: 'work',
    label: 'The canonical title of the work',
    keyRef: 'https://musicbrainz.org/doc/Work'
  }, {
    key: 'grouping',
    label: 'Content group description.'
  }, {
    key: 'track',
    label: 'Track number',
    toText: v => v.of ? `${v.no} / ${v.of}` : `${v.no}`
  }, {
    key: 'totaltracks',
    label: 'The total number of tracks'
  }, {
    key: 'disk',
    label: 'Disk or media number',
    toText: v => v.of ? `${v.no} / ${v.of}` : `${v.no}`
  }, {
    key: 'totaldiscs',
    label: 'The total number of discs'
  }, {
    key: 'discsubtitle',
    label: 'The Media Title given to a specific disc'
  }, {
    key: 'artist',
    label: 'Artist'
  }, {
    key: 'artists',
    label: 'Artists'
  }, {
    key: 'albumartist',
    label: 'Album artist'
  }, {
    key: 'artistsort',
    label: 'Track artist sort name'
  }, {
    key: 'albumartistsort',
    label: 'Album artist sort name'
  }, {
    key: 'originalartist',
    label: 'Original track artists.'
  }, {
    key: 'composer',
    label: 'Composer'
  }, {
    key: 'composersort',
    label: 'Composer, formatted for alphabetic ordering'
  }, {
    key: 'lyricist',
    label: 'Lyricist, formatted for alphabetic ordering'
  }, {
    key: 'writer',
    label: 'Writer'
  }, {
    key: 'conductor',
    label: 'Conductor'
  }, {
    key: 'remixer',
    label: 'Remixer(s)'
  }, {
    key: 'arranger',
    label: 'Arranger'
  }, {
    key: 'engineer',
    label: 'Engineer(s)'
  }, {
    key: 'producer',
    label: 'Producer(s)'
  }, {
    key: 'djmixer',
    label: 'Mix-DJ(s)'
  }, {
    key: 'mixer',
    label: 'Mixed by'
  }, {
    key: 'technician',
    label: 'Technician who digitized subject'
  }, {
    key: 'performer:instrument',
    label: 'Performer relationship types, instrument can also be vocals.'
  }, {
    key: 'year',
    label: 'Release year'
  }, {
    key: 'album',
    label: 'Album'
  }, {
    key: 'albumsort',
    label: 'Album title, formatted for alphabetic ordering'
  }, {
    key: 'originalalbum',
    label: 'Original release title'
  }, {
    key: 'compilation',
    label: 'Is part of compilation'
  }, {
    key: 'date',
    label: 'Release date'
  }, {
    key: 'originaldate',
    label: 'Original release date'
  }, {
    key: 'originalyear',
    label: 'Original release year',
  }, {
    key: 'media',
    label: 'Release Format'
  }, {
    key: 'label',
    label: 'Release label name(s)'
  }, {
    key: 'catalognumber',
    label: 'Release catalog number(s)',
    keyRef: 'https://musicbrainz.org/doc/Release/Catalog_Number'
  }, {
    key: 'genre',
    label: 'Genres'
  }, {
    key: 'mood',
    label: 'Keywords to reflect the mood of the audio'
  }, {
    key: 'comment',
    label: 'Comments'
  }, {
    key: 'notes',
    label: 'Notes'
  }, {
    key: 'lyrics',
    label: 'Lyricist'
  }, {
    key: 'key',
    label: 'Initial key'
  }, {
    key: 'rating',
    label: 'Object holding rating score [0..1]'
  }, {
    key: 'bpm',
    label: 'Beats Per Minute (BPM)'
  }, {
    key: 'show',
    label: 'TV show title'
  }, {
    key: 'showsort',
    label: 'TV show sorting title'
  }, {
    key: 'podcast',
    label: 'ToDo',
    keyRef: 'https://github.com/Borewit/music-metadata/issues/13'
  }, {
    key: 'podcasturl',
    label: 'ToDo',
    keyRef: 'https://github.com/Borewit/music-metadata/issues/13'
  }, {
    key: 'releasestatus',
    label: 'Releases status',
    keyRef: mbBaseUrl + '/History:Release_Status'
  }, {
    key: 'releasetype',
    label: 'Release type',
    keyRef: 'https://musicbrainz.org/doc/Release_Group/Type'
  }, {
    key: 'releasecountry',
    label: 'Release country',
    keyRef: mbBaseUrl + '/Release_Country'
  }, {
    key: 'barcode',
    label: 'Release barcode',
    keyRef: 'https://musicbrainz.org/doc/Barcode',
    valueRef: (v) => 'https://www.barcodelookup.com/' + v
  }, {
    key: 'isrc',
    label: 'ISRC',
    keyRef: 'https://musicbrainz.org/doc/ISRC',
    valueRef: (v) => 'https://isrcsearch.ifpi.org/#!/search?isrcCode=' + v + '&tab=lookup&showReleases=0&start=0&number=10'
  }, {
    key: 'asin',
    label: 'ASIN',
    keyRef: 'https://musicbrainz.org/doc/ASIN'
  }, {

    key: 'script',
    label: 'Release Script',
    keyRef: 'https://picard.musicbrainz.org/docs/tags/'
  }, {
    key: 'language',
    label: 'Language used in metadata'
  }, {
    key: 'copyright',
    label: 'Copyright.'
  }, {
    key: 'license',
    label: 'License'
  }, {
    key: 'encodedby',
    label: 'Encoded by (person/organisation)'
  }, {
    key: 'encodersettings',
    label: 'Encoder Settings'
  }, {
    key: 'gapless',
    label: 'Gapless album indicator'
  }, {
    key: 'musicbrainz_recordingid',
    label: 'Release recording MBID',
    keyRef: 'https://musicbrainz.org/doc/Recording',
    valueRef: (v) => mbBaseUrl + '/recording/' + v
  }, {
    key: 'musicbrainz_trackid',
    label: 'Release track MBID',
    keyRef: 'https://musicbrainz.org/doc/MusicBrainz_Identifier',
    valueRef: (v) => mbBaseUrl + '/recording/' + v
  }, {
    key: 'musicbrainz_albumid',
    label: 'Album release MBID',
    keyRef: 'https://musicbrainz.org/doc/Release',
    valueRef: (v) => mbBaseUrl + '/release/' + v
  }, {
    key: 'musicbrainz_artistid',
    label: 'Track artists MBIDs',
    keyRef: 'https://musicbrainz.org/doc/Artist',
    valueRef: (v) => mbBaseUrl + '/artist/' + v
  }, {
    key: 'musicbrainz_albumartistid',
    label: 'Album artists',
    keyRef: 'https://musicbrainz.org/doc/Artist',
    valueRef: (v) => mbBaseUrl + '/artist/' + v
  }, {
    key: 'musicbrainz_releasegroupid',
    label: 'Release group MBID',
    keyRef: 'https://musicbrainz.org/doc/Release_Group',
    valueRef: (v) => mbBaseUrl + '/release-group/' + v
  }, {
    key: 'musicbrainz_workid',
    label: 'Work MBID',
    keyRef: 'https://musicbrainz.org/doc/Work',
    valueRef: (v) => mbBaseUrl + '/work/' + v
  }, {
    key: 'musicbrainz_trmid',
    label: 'TRM Acoustic ID',
    keyRef: 'https://musicbrainz.org/doc/Fingerprinting#TRM'
  }, {
    key: 'musicbrainz_discid',
    label: 'Disc ID',
    keyRef: 'https://musicbrainz.org/doc/Disc_ID'
  }, {
    key: 'acoustid_id',
    label: 'Acoust ID',
    keyRef: 'https://en.wikipedia.org/wiki/Acoustic_fingerprint',
    valueRef: (v) => 'https://acoustid.org/track/' + v
  }, {
    key: 'acoustid_fingerprint',
    label: 'AcoustID Fingerprint',
    keyRef: 'https://acoustid.org/'
  }, {
    key: 'musicip_puid',
    label: 'PUIDs',
    keyRef: 'https://musicbrainz.org/doc/Fingerprinting#PUID'
  }, {
    key: 'musicip_fingerprint',
    label: 'MusicIP Fingerprint), not sure which algorithm.'
  }, {
    key: 'discogs_artist_id',
    label: 'Discogs artist ID',
    valueRef: (v) => 'https://www.discogs.com/artist/' + v
  }, {
    key: 'discogs_label_id',
    label: 'Discogs label ID',
    valueRef: (v) => 'https://www.discogs.com/label/' + v
  }, {
    key: 'discogs_master_release_id',
    label: 'Discogs master release ID',
    valueRef: (v) => 'https://www.discogs.com/master/' + v
  }, {
    key: 'discogs_votes',
    label: 'Discogs votes'
  }, {
    key: 'discogs_rating',
    label: 'Discogs rating'
  }, {
    key: 'discogs_release_id',
    label: 'Discogs release identifier',
    valueRef: (v) => 'https://www.discogs.com/release/' + v
  }, {
    key: 'website',
    label: 'URL of website'
  }, {
    key: 'averageLevel',
    label: 'Average gain level.'
  }, {
    key: 'peakLevel',
    label: 'Peak gain level.'
  }, {
    key: 'replaygain_track_peak',
    label: 'Replay track peak'
  }, {
    key: 'replaygain_track_gain',
    label: 'Replay track gain'
  }, {
    key: 'description',
    label: 'Description'
  }, {
    key: 'tvShow',
    label: 'TV Show'
  }, {
    key: 'tvShowSort',
    label: 'TV show title (alphabetic)'
  }, {
    key: 'tvSeason',
    label: 'TV Season'
  }, {
    key: 'tvEpisode',
    label: 'TV Episode'
  }, {
    key: 'tvEpisodeId',
    label: 'TV Episode ID'
  }, {
    key: 'tvNetwork',
    label: 'TV Network'
  }/*, {
    key: 'picture',
    label: 'Embedded cover art'
  }*/
];
