import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-storage-usage',
  templateUrl: './storage-usage.component.html',
  styleUrls: ['./storage-usage.component.scss']
})
export class StorageUsageComponent implements OnInit {
  max = 0;
  used = 0;
  percentage = 0;
  constructor() { }

  ngOnInit(): void {
    window.navigator.storage.estimate().then(results => {
      this.max = results.quota;
      this.used = results.usage;
      this.percentage = results.usage / results.quota;
    });
  }

}
