<mat-toolbar color="primary">
  <button (click)="sidenavContainer.toggle()" mat-icon-button>
    <mat-icon>menu</mat-icon>
  </button>
  <ng-container [ngTemplateOutlet]="headerService.titleTemplate"></ng-container>
  <div class="spacer"></div>
  <button [matMenuTriggerFor]="menuMenu" mat-icon-button style="height: 2.5rem; width: 2.5rem; padding: 0;">
    <img [src]="(auth.user$ | async)?.picture"
         alt="profile-pic"
         class="profile-image"
         referrerpolicy="no-referrer"
         style="height: 2.5rem; width: 2.5rem; border-radius: 100%;">
  </button>
  <mat-menu #menuMenu>
    <button (click)="auth.logout()" mat-menu-item>Logout</button>
  </mat-menu>
</mat-toolbar>
<mat-sidenav-container autosize class="main-container">
  <mat-sidenav #sidenavContainer mode="over" style="min-width: 300px">
    <mat-list>
      <mat-nav-list>
        <a (click)="sidenavContainer.close()" [routerLink]="['/media']" mat-list-item>
          <mat-icon>music_note</mat-icon>
          Media
        </a>
        <a (click)="sidenavContainer.close()" mat-list-item routerLink="/playlist/list">
          <mat-icon>playlist_add_check</mat-icon>
          Playlist
        </a>
        <a (click)="addPlaylist()" mat-list-item>
          <mat-icon>add_circle</mat-icon>
          Playlist
        </a>
        <mat-divider></mat-divider>
        <a (click)="sidenavContainer.close()" mat-list-item routerLink="/">
          All Playlist
        </a>
        <app-playlist-list (click)="saveState()" [drawer]="sidenavContainer" [list]="playlists"></app-playlist-list>
      </mat-nav-list>
    </mat-list>
  </mat-sidenav>
  <mat-sidenav-content class="content-background">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
