import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Song } from '../../../../../src/app/Models/song';
import { SongEditComponent } from '../song-edit/song-edit.component';

@Component({
  selector: 'app-song-card',
  templateUrl: './song-card.component.html',
  styleUrls: ['./song-card.component.scss'],
})
export class SongCardComponent implements OnInit {
  @Input() song: Song;
  @Input() showEdit = false;
  @HostBinding('class.active')
  @Input() active: boolean;
  constructor(private matDialog: MatDialog) { }

  ngOnInit(): void {}

  public editSong(song: Song): void {
    this.matDialog.open(SongEditComponent, {data: {...song}});

  }
}
