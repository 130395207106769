import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '../../../../../src/app/shared/angular-material.module';
import { ConfirmDirective } from './confirm.directive';
import { ConfirmComponent } from './confirm/confirm.component';



@NgModule({
  declarations: [
    ConfirmDirective,
    ConfirmComponent
  ],
  exports: [
    ConfirmDirective
  ],
  imports: [
    CommonModule,
    AngularMaterialModule
  ]
})
export class ConfirmDialogModule { }
