import {ISong} from './ISong';
import {ThumbnailSet} from './thumbnailSet';

export class Song implements ISong {
  album?: string;
  title: string;
  duration?: any;
  attributes?: string[];
  artists?: string[];
  id?: number;
  images?: ThumbnailSet[];
  originalUrl?: string;
  ordering?: number;
}
