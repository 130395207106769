import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from './Components/main/main.component';
import {LoginComponent} from './Components/login/login.component';
import {AuthGuard} from "@auth0/auth0-angular";

const routes: Routes = [{
  path: '',
  component: MainComponent,
  children: [{
    path: 'main',
    loadChildren: () => import('../../projects/list-playlist/src/app/playlist.module').then(m => m.PlaylistModule),
  }, {
    path: 'media',
    loadChildren: () => import('../../projects/media/src/app/media.module').then(m => m.MediaModule),
  }, {
    path: '**',
    redirectTo: 'main'
  }
  ],
  canActivate: [AuthGuard],
}, {
  path: 'login',
  component: LoginComponent
}, {
  path: '**',
  redirectTo: ''
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
