import { Directive, HostListener, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { ConfirmComponent } from './confirm/confirm.component';

@Directive({
  selector: '[appConfirm]'
})
export class ConfirmDirective {
  @Input() appConfirm;
  @Input() confirmTitle: any;
  @Input() confirmMessage: any;
  @Input() icon: any;
  constructor(private matDialog: MatDialog) { }
  @HostListener('click')
  public clickListener(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'common-dialog-container';
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      title: this.confirmTitle,
      message: this.confirmMessage,
      icon: this.icon
    };
    this.matDialog.open(ConfirmComponent, dialogConfig).afterClosed().pipe(tap((isOk) => {
      if (isOk) {
        this.appConfirm();
      }
    })).subscribe();
  }
}
