import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import {AuthService} from '@auth0/auth0-angular';
import {tap} from 'rxjs/operators';
import {EditPlaylistComponent} from '../../../../projects/list-playlist/src/app/edit-playlist/edit-playlist.component';
import {ThumbnailSet} from '../../Models/thumbnailSet';
import {HeaderService} from '../../Services/header.service';
import {PlayerService} from '../../Services/player.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Playlist} from '../../Models/playlist';
import {MatDialog} from '@angular/material/dialog';
import {CookieService} from 'ngx-cookie-service';
import {Theme} from './theme';
import _ from 'underscore';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainComponent implements OnInit, OnDestroy, AfterViewInit {
  subscriptions = new Subscription();
  @HostBinding('class.player-open') playerOpen;
  playlists: Playlist[] = null;
  themes = [
    {
      name: 'default',
      className: 'default-theme',
      link: 'https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzMzODVjYyIsIj9lcjwjYzJkYWYwIiwiO2VyPCMyMDY4Yjl$LCIlPmBePCNlM2Y0ZmIiLCI~ZXI8I2Y3ZmNmZSIsIjtlcjwjZDhlZmY5fiwid2Fybj5gXjwjZmYwMDAwIiwiP2VyPCNmZmIzYjMiLCI7ZXI8I2ZmMDAwMH4sIj9UZXh0PCMwMDAwMDAiLCI~PTwjZDJlYWZmIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMyYzJjMmN$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz50cnVlLCJ2ZXJzaW9uPjExfQ=='
    },
    {
      name: 'secondary',
      className: 'secondary-theme',
      link: 'https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzU4N2U1MiIsIj9lcjwjY2RkOGNiIiwiO2VyPCMzZDYxMzh$LCIlPmBePCNlMGU5ZDgiLCI~ZXI8I2Y2ZjhmMyIsIjtlcjwjZDNkZmM4fiwid2Fybj5gXjwjZmYwMDAwIiwiP2VyPCNmZmIzYjMiLCI7ZXI8I2ZmMDAwMH4sIj9UZXh0PCMwMDAwMDAiLCI~PTwjZmZmZGRiIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMyYzJjMmN$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz50cnVlfQ=='
    }
  ] as Theme[];
  selectedTheme$ = new BehaviorSubject<Theme>(null);
  darkMode$ = new BehaviorSubject<boolean>(false);
  public image$ = new BehaviorSubject<string>(null);
  user$ = this.auth.user$;

  constructor(public playerService: PlayerService,
              public auth: AuthService,
              public headerService: HeaderService,
              private matDialog: MatDialog,
              private cookieService: CookieService,
              private renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.selectedTheme$.next(this.getThemeOrFirst());
    this.darkMode$.next(this.getDarkOrDefault());
    this.subscriptions.add(this.selectedTheme$.subscribe(theme => {
      try {
        this.renderer.addClass(document.body, theme.className);
      } catch {
      }
    }));
    this.subscriptions.add(this.darkMode$.subscribe(darkMode => {
      try {
        if (darkMode) {
          this.renderer.addClass(document.body, 'theme-alternate');
        } else {
          this.renderer.removeClass(document.body, 'theme-alternate');
        }
      } catch {
      }
    }));
    this.subscriptions.add(this.playerService.currentSong$
      .pipe(
        tap(song => {
          if (song == null) {
            this.image$.next('');
            return;
          }
          const s = this.getLargest(song.images);
          this.image$.next(s.url);
        }))
      .subscribe());
    this.subscriptions.add(this.playerService.showPlayer$
      .subscribe(show => setTimeout(() => {
        this.playerOpen = show;
      })));
    this.subscriptions.add(this.playerService.playList$
      .subscribe(playlists => {
        this.playlists = playlists;
        this.setActivePlaylists();
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit(): void {
  }

  public addPlaylist(): void {
    this.matDialog.open(EditPlaylistComponent, {
      data: {},
      height: 'calc(100vh - 2rem)',
      width: 'calc(100vw - 2rem)',
      maxHeight: undefined,
      maxWidth: undefined
    })
      .afterClosed()
      .pipe(tap(playlist => {
        this.playerService.savePlaylist(playlist).subscribe();
      }))
      .subscribe();
  }

  public saveState(): void {
    setTimeout(() => {
      window.sessionStorage.setItem('playlistIsActive', JSON.stringify(this.storableObject(this.playlists)));
    }, 50);
  }

  private getThemeOrFirst(): Theme {
    let themeName = this.cookieService.get('themeName');
    if (!themeName) {
      themeName = 'default';
    }
    return this.themes.find(t => t.name === themeName);
  }

  private getDarkOrDefault(): boolean {
    return this.cookieService.get('isDarkMode') === 'true';
  }

  private getLargest(images: ThumbnailSet[]): ThumbnailSet {
    let image = _.max(images, i => i?.resolution?.width ?? 0);
    if (!image) {
      image = images[0];
    }
    return image;
  }

  private storableObject(playlists: Playlist[]): any {
    return playlists.map(p => ({
      id: p.id,
      isActive: p.isActive,
      children: this.storableObject(p.children)
    }));
  }

  private setActivePlaylists(): void {
    const playlists: Playlist[] = JSON.parse(window.sessionStorage.getItem('playlistIsActive') ?? '[]');
    this.setActive(playlists);
  }

  private setActive(playlists: Playlist[]): void {
    playlists.forEach(p => {
      const playlist = this.findPlaylist(p.id, this.playlists);
      if (playlist) {
        playlist.isActive = p.isActive;
      }
      this.setActive(p.children);
    });
  }

  private findPlaylist(id: number, playlists: Playlist[]): Playlist {
    let found = playlists.find(p => p.id === id);
    if (found) {
      return found;
    }
    for (const p of playlists) {
      found = this.findPlaylist(id, p.children);
      if (found) {
        break;
      }
    }
    return found;
  }
}
