import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import { ISong } from '../Models/ISong';
import {Song} from '../Models/song';
import {Playlist} from '../Models/playlist';
import {HttpClient} from '@angular/common/http';
import {map, take, tap} from 'rxjs/operators';

const defaultCallbackTime = 5 * 60 * 1000;

function removeParent(b: any): any {
  if (Array.isArray(b)){
    const results = [];
    b.forEach(a => {
      if (a.children){
        a.children = removeParent(a.children);
      }
      results.push(removeParent(a));
    });
    return results;
  }
  return {...b, parent: null};
}

function equals(a: any, b: any): boolean{
  return JSON.stringify(removeParent(a)) === JSON.stringify(removeParent(b));
}
@Injectable({
  providedIn: 'root'
})
export class DataStorageService {
  baseUrl = '';
  constructor(private httpClient: HttpClient) {
    this.initLoadData();
    setTimeout(() => {
      this.loadData();
    }, defaultCallbackTime);
  }
  private playlistList$ = new BehaviorSubject<Playlist[]>(null);
  private songs$ = new BehaviorSubject<Song[]>(null);
  private attributes$ = new BehaviorSubject<string[]>([]);
  private static loadLocalStorage<T>(key: string): T {
    const storageValue = window.localStorage.getItem(key);
    const obj = JSON.parse(storageValue);
    return obj as T;
  }
  reloadData(): void{
    this.loadData();
  }
  getPlaylist(): Observable<Playlist[]> {
    this.httpClient.get<Playlist[]>(`${this.baseUrl}/api/playlists`)
      .pipe(take(1), tap(playlists => {
        if (equals(playlists, this.playlistList$.getValue())) { return; }
        this.playlistList$.next(playlists);
        window.localStorage.setItem('playlists', JSON.stringify(removeParent(playlists)));
      }))
      .subscribe();
    return this.playlistList$;
  }

  getAttributes(): Observable<string[]> {
    this.httpClient.get<any[]>(`${this.baseUrl}/api/attributes`)
      .pipe(take(1), tap(attributes => {
        const attrs = attributes.map(a => a.name);
        if (equals(attrs, this.attributes$.getValue())) { return; }
        this.attributes$.next(attrs);
        window.localStorage.setItem('attributes', JSON.stringify(attributes));
      }))
      .subscribe();
    return this.attributes$;
  }

  getSongs(): Observable<Song[]>  {
    this.httpClient.get<Song[]>(`${this.baseUrl}/api/songs`).pipe(take(1), tap(songs => {
      if (equals(songs, this.songs$.getValue())) { return; }
      this.songs$.next(songs);
      window.localStorage.setItem('songs', JSON.stringify(songs));
    })).subscribe();
    return this.songs$;
  }

  private loadData(): void {
    this.getAttributes();
    this.getSongs();
    this.getPlaylist();
    setTimeout(() => {
      this.loadData();
    }, defaultCallbackTime);
  }

  private initLoadData(): void {
    this.songs$.next(DataStorageService.loadLocalStorage<Song[]>('songs'));
    this.playlistList$.next(DataStorageService.loadLocalStorage<Playlist[]>('playlists'));
    this.attributes$.next(DataStorageService.loadLocalStorage<string[]>('attributes'));
  }

  public uploadSong(song: ISong, file: File): Observable<any> {
    const form = new FormData();
    form.append('song', JSON.stringify(song));
    form.append('file', file, file.name);
    return this.httpClient.post('/api/songs/upload', form);
  }
  public uploadMedia(file: File): Observable<string> {
    const form = new FormData();
    form.append('file', file, file.name);
    return this.httpClient.post<string>('/api/songs/uploadFile', form);
  }

  public delete(song: Song): Observable<any>  {
    return this.httpClient.delete(`/api/songs/${song.id}`);
  }

  public saveSong(song: Song): Observable<any> {
    return this.httpClient.put(`/api/songs/${song.id}`, song);
  }
}
