import {Song} from './song';

export class Playlist {
  title: string;
  includedAttributes?: string[];
  excludedAttributes?: string[];
  children?: Playlist[];
  id: number;
  parentId: number;
  depth: number;
  parent: Playlist;
  songs: Song[];
  public image: string;
  public isActive: boolean;
}
