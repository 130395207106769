import {Component, Input, OnInit} from '@angular/core';
import {tap} from 'rxjs/operators';
import {Playlist} from '../../Models/playlist';
import {MatDialog} from '@angular/material/dialog';
import {DataStorageService} from '../../Services/data-storage.service';
import {PlayerService} from '../../Services/player.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSidenav} from '@angular/material/sidenav';

@Component({
  selector: 'app-playlist-list',
  templateUrl: './playlist-list.component.html',
  styleUrls: ['./playlist-list.component.scss']
})
export class PlaylistListComponent implements OnInit {
  @Input() list: Playlist[];
  @Input() drawer: MatSidenav;
  @Input() indent = 0;
  @Input() parent = '';

  constructor(
    private snackbar: MatSnackBar,
    private playerService: PlayerService,
    private dialog: MatDialog,
    private dataService: DataStorageService
  ) {
  }

  public delete = (playlist: Playlist) => {
    this.playerService.deletePlaylist(playlist)
      .pipe(tap(results => {
        this.snackbar.open(`Deleted ${playlist.title}`, null, {duration: 500});
        this.dataService.getPlaylist();
      }))
      .subscribe();
  };

  ngOnInit(): void {
  }

  public toggleOpen(playlist, force = false): void {
    playlist.isActive = !playlist.isActive || force;
    this.list.filter(l => l !== playlist).forEach(p => {
      p.isActive = false;
    });
  }
}
