import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {Playlist} from '../Models/playlist';
import {Song} from '../Models/song';
import {DataStorageService} from './data-storage.service';
import {filter, take, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {SongsService} from './songs.service';

@Injectable({
  providedIn: 'root'
})
export class PlayerService implements OnDestroy{
  showPlayer$ = new BehaviorSubject<boolean>(false);
  playList$ = new BehaviorSubject<Playlist[]>(null);
  songList$ = new BehaviorSubject<Song[]>(null);
  currentSong$ = new BehaviorSubject<Song>(null);
  autoplay$ = new BehaviorSubject<boolean>(false);
  baseUrl = '';
  private subscriptions = new Subscription();
  constructor(
    private dataStorageService: DataStorageService,
    private songsService: SongsService,
    private httpClient: HttpClient,
    private router: Router
  ) {
    dataStorageService.getPlaylist().subscribe(playlist => this.playList$.next(playlist));
    this.currentSong$.subscribe(song => {
      this.showPlayer$.next(song != null);
    });
  }

  ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

  getSongs(playlist: any): Observable<Song[]> {
    this.songsService.get(playlist).subscribe(songs => this.songList$.next(songs));
    return this.songList$;
  }

  songs(songs: Song[]): void {
    this.songList$.next(songs);
  }

  startSong(song: Song): void {
    this.autoplay$.next(true);
    this.router.navigate(['main', {outlets: {player: ['play', song.id]}}]).finally();
  }

  youtubeSong(url: any): Observable<Song> {
    const results = new Subject<Song>();
    const path = `${this.baseUrl}/api/YouTube?url=${encodeURIComponent(url)}`;
    this.httpClient.get<Song>(path)
      .pipe(take(1), tap(song => results.next(song)))
      .subscribe();
    return results;
  }

  savePlaylist(playlist: Playlist): Observable<Playlist> {
    this.removeChildren(playlist);
    const post = Object.assign({}, playlist);
    let result;
    if (post.id) {
      result = this.httpClient.put(`${this.baseUrl}/api/playlists/${post.id}`, post);
    } else{
      result = this.httpClient.post(`${this.baseUrl}/api/playlists/`, post);
    }
    return result;
  }

  getPlayList(playlistId: number): Observable<Playlist> {
    const results = new BehaviorSubject<Playlist>(null );
    this.subscriptions.add(this.playList$
      .pipe(filter(p => p != null),
        tap(playlists => {
          const playlist = (this.flattenPlaylist(playlists)).find(p => p.id === playlistId);
          results.next(playlist);
      }))
      .subscribe());
    return results;
  }

  flattenPlaylist(pl: Playlist[], result: Playlist[] = [], depth = 0, parent = null): Playlist[] {
    pl.forEach(p => {
      p.depth = depth;
      p.parent = parent;
      result.push(p);
      this.flattenPlaylist(p.children, result, depth + 1, p);
    });
    return result;
  }

  public deletePlaylist(playlist: Playlist): Observable<any> {
    return this.httpClient.delete('/api/playlists/' + playlist.id);
  }

  private removeChildren(playlist: Playlist): void {
    playlist.children = null;
    if (playlist.parent) {
      this.removeChildren(playlist.parent);
    }
  }
}
