import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(public auth: AuthService,
              private router: Router) { }

  ngOnInit(): void {
    this.auth.isAuthenticated$
      .pipe(tap(async isAuth => {
        if (isAuth) {
          const redirectTo = sessionStorage.getItem('redirectUrl');
          await this.router.navigateByUrl(redirectTo);
          return;
        }
        this.auth.loginWithRedirect();
      }))
      .subscribe();
  }

}
