import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent {
  icon = '';
  title = '';
  message = '';
  okButtonLabel = 'Ok';
  cancelButtonLabel = 'Cancel';

  constructor(
    public dialogRef: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.title = data.title;
    this.icon = data.icon;
    this.message = data.message;

  }

  getValue(value: boolean): void {
    this.dialogRef.close(value);
  }

}
