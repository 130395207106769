<h1 mat-dialog-title style="display: flex">
  Edit Song
  <div class="spacer"></div>
  <button mat-icon-button mat-dialog-close=""><mat-icon>close</mat-icon></button>
</h1>
<mat-dialog-content>
  <ng-form [formGroup]="formGroup">
    <mat-tab-group>
      <mat-tab label="Details">
        <div appDnd (fileDropped)="uploadAudio($event)">
          <mat-form-field>
            <mat-label>Title</mat-label>
            <input matInput formControlName="title">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Duration</mat-label>
            <input matInput formControlName="duration">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Album</mat-label>
            <input matInput formControlName="album">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Original Url</mat-label>
            <input matInput formControlName="originalUrl">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Path</mat-label>
            <input matInput formControlName="path">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Ordering</mat-label>
            <input matInput formControlName="ordering">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Artists</mat-label>
            <input matInput formControlName="artists">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Attributes</mat-label>
            <input matInput formControlName="attributes">
          </mat-form-field>
        </div>
      </mat-tab>
      <mat-tab label="Images">
        <div class="carousel">
          @for (image of data.images; track image; let i = $index) {
            <div class="slide"
              [style.left]="'calc((949px + 200px + 1rem) * ' + (i + offset) + ')'"
              appDnd (fileDropped)="droppedFile($event, image)"
              >
              <button style="position: absolute; right: 220px;" mat-button (click)="remove(image)">
                <mat-icon>delete</mat-icon>
              </button>
              <div class="picture-box"
                (wheel)="zoomImage($event, image)">
                @if (image.url) {
                  <img
                    [src]="image.url"
                    [style.width.px]="image.resolution.width * image.scale"
                    [style.height.px]="image.resolution.height * image.scale"
                    alt="Display Image">
                }
              </div>
              <div class="resolution">
                <mat-form-field>
                  <mat-label>Url</mat-label>
                  <input matInput type="text" [(ngModel)]="image.url"
                    (ngModelChange)="urlUpdated(image, $event)"
                    [ngModelOptions]="{standalone: true}">
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>Width</mat-label>
                    <input matInput type="number" [(ngModel)]="image.resolution.width"
                      [ngModelOptions]="{standalone: true}">
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Height</mat-label>
                      <input matInput type="number" [(ngModel)]="image.resolution.height"
                        [ngModelOptions]="{standalone: true}">
                      </mat-form-field>
                    </div>
                  </div>
                }
                <div class="controls">
                  <button mat-button (click)="moveCarousel(-1)">
                    <mat-icon>navigate_before</mat-icon>
                  </button>
                  <button mat-button (click)="moveCarousel(1)">
                    <mat-icon>navigate_next</mat-icon>
                  </button>
                  <button mat-button (click)="addImage()">
                    <mat-icon>add_circle</mat-icon>
                  </button>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </ng-form>
      </mat-dialog-content>
      <mat-dialog-actions>
        <div class="spacer"></div>
        <button mat-icon-button mat-dialog-close="">Cancel</button>
        <button mat-icon-button (click)="save()" [disabled]="!formGroup.valid">OK</button>
      </mat-dialog-actions>
