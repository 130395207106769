import { Pipe, PipeTransform } from '@angular/core';
import { Song } from '../../../../src/app/Models/song';

@Pipe({
  name: 'songSearch'
})
export class SongSearchPipe implements PipeTransform {

  transform(value: Song[], ...args: unknown[]): Song[] {
    const search = (args[0] as string)?.toLowerCase();
    if (search == null || search.length < 1) { return value; }
    return value.filter(s => s.title?.toLowerCase().includes(search));
  }

}
