import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Song} from '../../../../../../src/app/Models/song';

@Component({
  selector: 'app-song-thumbnail',
  templateUrl: './song-thumbnail.component.html',
  styleUrls: ['./song-thumbnail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SongThumbnailComponent implements OnInit {
  @Input() song: Song;
  url$ = new BehaviorSubject(null);

  constructor() {
  }

  ngOnInit(): void {
    const url = this.song.images
      .sort((a, b) => a.resolution?.width - b.resolution?.width)?.[0]?.url;
    this.url$.next(url);
  }

}
