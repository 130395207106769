<div class="song-card-wrapper">
  <app-song-thumbnail [song]="song"></app-song-thumbnail>
  <div class="spacer">
    {{song.title}}
    @if (showEdit) {
      <button mat-icon-button (click)="editSong(song)">
        <mat-icon>edit</mat-icon>
      </button>
    }
  </div>
  <ng-content></ng-content>
</div>
