import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as path from 'path';
import _ from 'underscore';
import {tap} from 'rxjs/operators';
import {Song} from '../../../../../src/app/Models/song';
import {ThumbnailSet} from '../../../../../src/app/Models/thumbnailSet';
import {SongsService} from '../../../../../src/app/Services/songs.service';

@Component({
  selector: 'app-song-edit',
  templateUrl: './song-edit.component.html',
  styleUrls: ['./song-edit.component.scss']
})
export class SongEditComponent implements OnInit {
  public formGroup = new FormGroup({
    title: new FormControl(),
    duration: new FormControl(),
    album: new FormControl(),
    originalUrl: new FormControl(),
    path: new FormControl(),
    ordering: new FormControl(),
    images: new FormControl(),
    artists: new FormControl(),
    attributes: new FormControl(),
  });
  public offset = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data: Song,
              private songService: SongsService,
              private matDialogRef: MatDialogRef<SongEditComponent>) {
    if (data.images.length < 1) {
      this.addImage();
    }
    data.images.forEach(i => i.scale = 1);
    this.formGroup.get('title').setValue(data.title);
    this.formGroup.get('duration').setValue(data.duration);
    this.formGroup.get('album').setValue(data.album);
    this.formGroup.get('originalUrl').setValue(data.originalUrl);
    this.formGroup.get('images').setValue(data.images);
    this.formGroup.get('artists').setValue(data.artists);
    this.formGroup.get('attributes').setValue(data.attributes);
  }

  ngOnInit(): void {
  }

  public moveCarousel(distance: number): void {
    this.offset -= distance;
    if ((this.offset * -1) > this.data.images.length - 1) {
      this.offset = 0;
    }
    if ((this.offset * -1) < 0) {
      this.offset = (this.data.images.length - 1) * -1;
    }
  }

  public addImage(): void {
    this.data.images.push({
      url: '',
      scale: 1,
      resolution: {
        width: null,
        height: null
      }
    });
    this.offset = (this.data.images.length - 1) * -1;
  }

  public urlUpdated(image: ThumbnailSet, currentUrl: string): void {
    const img = new Image();
    img.onload = (event) => {
      image.resolution.height = img.naturalHeight;
      image.resolution.width = img.naturalWidth;
    };
    img.src = currentUrl;
  }

  public droppedFile(fileList: FileList, image: ThumbnailSet): void {
    if (fileList.length > 1) {
      for (let i = 0; i < fileList.length; i++) {
        const img = {
          url: '',
          scale: 1,
          resolution: {
            width: null,
            height: null
          }
        };
        this.data.images.push(img);
        this.loadFile(fileList.item(i), img);
      }
    } else {
      this.loadFile(fileList.item(0), image);
    }
  }

  public zoomImage($event: WheelEvent, image: ThumbnailSet): void {
    const zoomAmount = .125;
    image.scale ??= 1;
    if ($event.deltaY > 0) {
      image.scale -= zoomAmount;
    } else {
      image.scale += zoomAmount;
    }
    if (image.scale < zoomAmount) {
      image.scale = zoomAmount;
    }
    if (image.scale > 4) {
      image.scale = 4;
    }
  }

  public save(): void {
    this.songService.saveSong(this.data)
      .pipe(tap(song => {
        this.matDialogRef.close();
      }))
      .subscribe();
  }

  public remove(image: ThumbnailSet): void {
    this.data.images = _.without(this.data.images, image);
  }

  public uploadAudio(fileList: FileList): void {
    this.songService.uploadMedia(fileList.item(0))
      .pipe(tap(newUrl => {
        //TODO need to upload the song and get the ByteArray
        console.log(path);
        this.formGroup.get('path').setValue(newUrl);
      }))
      .subscribe();
  }

  private loadFile(file: File, image: ThumbnailSet): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.resizeImage(reader.result.toString(), image);
    };
  }

  private resizeImage(url: string, image: ThumbnailSet): void {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const widthScale = 1200 / img.naturalWidth;
      const heightScale = 1200 / img.naturalHeight;
      const scaleAmount = Math.min(Math.min(widthScale, heightScale), 1);
      canvas.width = img.naturalWidth * scaleAmount;
      canvas.height = img.naturalHeight * scaleAmount;
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      const newUrl = canvas.toDataURL();
      image.url = newUrl;
      this.urlUpdated(image, newUrl);
    };
  }
}
