import { Injectable } from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {map, take, tap} from 'rxjs/operators';
import { ISong } from '../Models/ISong';
import {Playlist} from '../Models/playlist';
import {Song} from '../Models/song';
import {DataStorageService} from './data-storage.service';

@Injectable({
  providedIn: 'root'
})
export class SongsService {
  private allSongs$: Observable<Song[]>;
  private playlistList$: Observable<Playlist[]>;
  private playlist$ = new BehaviorSubject<string>(null);
  private readonly songList$: Observable<Song[]>;

  constructor(
    private dataStorageService: DataStorageService
  ) {
    this.playlistList$ = dataStorageService.getPlaylist();
    this.allSongs$ = dataStorageService.getSongs();
    this.songList$ = combineLatest([this.allSongs$, this.playlist$, this.playlistList$]).pipe(map(([songs, playlist, playlistList]) => {
      if (songs == null || playlist == null || playlistList == null) {
        return;
      }
      const parts = playlist.split('>').map(p => this.findPlaylist(p, playlistList) as Playlist).map(p => p.includedAttributes);
      let attr = [];
      parts.forEach(p => {
        attr = [...attr, ...p];
      });
      return songs.filter(song => attr.every(p => song.attributes.some(a => a.toLowerCase() === p.toLowerCase())));
    }));
  }
  getPlaylists(): Observable<Playlist[]>{
    return this.playlistList$;
  }
  get(playlist: any): Observable<Song[]> {
    this.playlist$.next(playlist);
    return this.songList$;
  }
  getSongs(): Observable<Song[]> {
    return this.allSongs$;
  }

  private findPlaylist(name: string, playlist: Playlist[] = null): Playlist {
    let found = null;
    playlist?.forEach(p => {
      if (p.title === name) {
        found = p;
      }
      if (p.children && !found) {
        found = this.findPlaylist(name, p.children);
      }
    });
    return found;
  }

  getSongById(songId: number): Observable<Song> {
    const results = new BehaviorSubject<Song>(null);
    this.allSongs$.pipe(tap(songs => {
      if (songs == null) {
        return;
      }
      const song = songs.find(s => s.id === Number(songId));
      results.next(song);
    })).subscribe();
    return results;
  }

  public uploadSong(song: ISong, file: File): Observable<ISong> {
    return this.dataStorageService.uploadSong(song, file);
  }
  public uploadMedia(file: File): Observable<string> {
    return this.dataStorageService.uploadMedia(file);
  }

  public delete(song: Song): Observable<ISong> {
    return this.dataStorageService.delete(song);
  }

  public saveSong(song: Song): Observable<ISong> {
    return this.dataStorageService.saveSong(song);
  }
}
